import PropTypes from 'prop-types'

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  materialUiTheme: PropTypes.object.isRequired,

  description: PropTypes.string,
  addressLong: PropTypes.string,
  addressShort: PropTypes.string,
  countryName: PropTypes.string,
  countryCode: PropTypes.string,
  timezone: PropTypes.string,
  timezoneName: PropTypes.string
})
