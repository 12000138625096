function copyValueToClipboard(obj) {
  const temp = document.createElement('input')
  document.body.appendChild(temp)
  temp.value = obj.value
  temp.select()
  document.execCommand('copy')
  document.body.removeChild(temp)
}

export default copyValueToClipboard
