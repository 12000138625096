export default function($scope, $http) {
  'ngInject'

  $scope.searchQuery = ''
  $scope.participants = []
  $scope.hiddenParticipants = []
  $scope.loading = false
  $scope.loadingHidden = false


  $scope.searchAttendances = function(query) {
    if (!query) return [];
    if (!$scope.searchUrl) throw new Error('missing search url');

    $scope.loading = true;
    const url = `${$scope.searchUrl}?query=${query}`;
    return $http.get(url).then(function successCallback(response) {
      $scope.loading = false;
      return response.data.data;
    })
  };

  $scope.searchHiddenAttendances = function(query) {
    if (!query) return [];
    if (!$scope.searchUrl) throw new Error('missing search url');

    $scope.loadingHidden = true;
    const url = `${$scope.searchUrl}?query=${query}`;
    return $http.get(url).then(function successCallback(response) {
      $scope.loadingHidden = false;
      return response.data.data;
    })
  };

  $scope.onSelect = function(item) {
    if (!alreadySelected(item)) $scope.participants.push(item)
    $scope.searchQuery = ''
  }

  $scope.onHiddenSelect = function(item) {
    if (!alreadySelected(item)) $scope.hiddenParticipants.push(item)
    $scope.searchQuery = ''
  }

  $scope.removeParticipant = function(participant) {
    $scope.participants.splice($scope.participants.indexOf(participant), 1)
  }

  $scope.removeHiddenParticipant = function(hiddenParticipant) {
    $scope.hiddenParticipants.splice($scope.hiddenParticipants.indexOf(hiddenParticipant), 1)
  }

  $scope.toggleParticipantVisibility = function(participant) {
    if ($scope.participants.includes(participant)) {
      $scope.removeParticipant(participant)
      $scope.hiddenParticipants.push(participant)
    } else {
      $scope.removeHiddenParticipant(participant)
      $scope.participants.push(participant)
    }
  }

  function alreadySelected(item) {
    return (
      $scope.participants.map(p => p.id).includes(item.id) ||
      $scope.hiddenParticipants.map(p => p.id).includes(item.id)
    )
  }
}
