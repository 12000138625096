const formFieldWrapperSelector = '.form-required-field'

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll(formFieldWrapperSelector).forEach((requiredInput) => {
    const input = requiredInput.querySelector('.form-control')
    if (input.value === '') requiredInput.classList.add('has-error')
    requiredInput.addEventListener('keyup', () => {
      (input.value === '') ?
        requiredInput.classList.add('has-error') :
        requiredInput.classList.remove('has-error')
    })
    input.dispatchEvent(new Event('keyup'))
  })
})
