import React from 'react';
import { DashboardPage, OmniaUiThemeProvider } from '@websummit/omnia-ui-lib';

class EventDashboard extends React.Component {
  getProps() {
    const apiOptions = JSON.parse(this.props.apiOptions ?? 'false');

    return {
      ...(apiOptions && { apiOptions: apiOptions }),
      createUrl: '/~events#/settings',
      createShowButton: true
    };
  }

  render() {
    return (
      <OmniaUiThemeProvider palette="websummit">
        <DashboardPage {...this.getProps()} />
      </OmniaUiThemeProvider>
    );
  }
}

export default EventDashboard;
