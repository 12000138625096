export default function($scope) {
  'ngInject'

  const keys = ['public_role_id', 'accreditation_id', 'registration_area_id']

  $scope.releases = []
  $scope.pendingReleases = 0
  $scope.pendingTickets = 0

  $scope.isPending = isPending
  $scope.setState = setState

  //

  function allRoleComponentsSet(role) {
    return keys.every((key) => role[key])
  }

  function anyRoleComponentsSet(role) {
    keys.forEach((key) => {
      if (role[key]) {
        true
      }
    })
    return false
  }

  function isPending(release) {
    return release.state === 'pending'
  }

  function pendingChanges() {
    let releases = 0
    let tickets = 0

    $scope.releases.forEach((release) => {
      if ($scope.isPending(release)) {
        releases += 1
        tickets += release.tickets_count
      }
    })
    return { releases, tickets }
  }

  function setState(release) {
    const releaseObj = release

    allRoleComponentsSet(releaseObj.role) ? releaseObj.state = 'pending' : releaseObj.state = 'invalid'

    const pending = pendingChanges()
    $scope.pendingReleases = pending.releases
    $scope.pendingTickets = pending.tickets
  }
}
