import $ from 'jquery';

function sendUpdatedPosition($target, $item) {
  const startPosition = $item.data('position');
  const oldPosition = $item.data('old-position');
  const pageSize = $target.data('per-page');
  const zeroIndexedPage = $target.data('page') - 1;

  const newPosition = $item.index() + 1 + pageSize * zeroIndexedPage;
  if (newPosition !== startPosition) {
    $.ajax({
      url: $item.data('update-url'),
      type: 'PUT',
      data: { list_item: { position: newPosition, old_position: oldPosition } },
      success() {
        window.location.reload();
      }
    });
  }
}

$(() => {
  $('div.manual-list-grid').sortable({
    handle: '.glyphicon.draggable-handle',
    stop(event, ui) {
      sendUpdatedPosition($(event.target), ui.item);
    }
  });
});

function trackMatomoEvent() {
  var category = $(this).data('category');
  var action = $(this).data('action');
  var name = $(this).data('name');
  var value = $(this).data('value');
  _paq.push(['trackEvent', category, action, name, value]);
}

$(document).on('click', '.js-track-matomo-event', trackMatomoEvent);
