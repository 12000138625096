import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Modal, Typography, withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import GdprCheckbox from './GdprCheckbox'

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  gdprText: {
    margin: '1em 0 1em 0',
    whiteSpace: 'pre-wrap'
  }
})

function GdprConsentModal(props) {
  const {
    classes,
    gdprText,
    gdprCheckboxText,
    openModal,
    onSubmit
  } = props;
  const [open, setOpen] = useState(openModal);
  const [disabled, setDisabled] = useState(true);

  return (
    <Modal
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <div className={classes.paper}>
        <Typography
          id='modal-title'
          variant='h4'
        >
          Help us help you
        </Typography>
        <Typography
          className={classes.gdprText}
          variant='body1'
        >
          {gdprText}
        </Typography>
        <Grid
          container
          justify='flex-end'
        >
          <GdprCheckbox
            gdprCheckboxText={gdprCheckboxText}
            onSelectGdprCheckbox={() => setDisabled(!disabled)}
          />
          <Button
            autoFocus
            onClick={
              () => {
                onSubmit();
                setOpen(false);
              }
            }
            color='primary'
            variant='contained'
            disabled={disabled}
          >
            Submit
          </Button>
        </Grid>
      </div>
    </Modal>
  )
}

GdprConsentModal.propTypes = {
  classes: PropTypes.object.isRequired,
  conferenceSlug: PropTypes.string.isRequired,
  openModal: PropTypes.bool.isRequired,
  gdprText: PropTypes.string.isRequired,
  gdprCheckboxText: PropTypes.string.isRequired
}

export default withStyles(styles)(GdprConsentModal);
