import React, { useState, useEffect } from 'react';
import Participant from './Participant';
import ScheduleAPI from '../../../api/schedule';
import { checkForAttendancesAlreadyAdded } from './utils';

const MAX_PARTICIPANTS = 50;

const SelectParticipants = ({
  conferenceSlug,
  timeslotVisibleParticipants,
  timeslotHiddenParticipants
}) => {
  const [references, setReferences] = useState('');
  const [invalidRef, setInvalidRef] = useState([]);
  const [visibleAttendances, setVisibleAttendances] = useState([]);
  const [hiddenAttendances, setHiddenAttendances] = useState([]);
  const [alreadyAdded, setAlreadyAdded] = useState([]);
  const [loading, setLoading] = useState(false);
  const [capacityReached, setCapacityReached] = useState(false);

  useEffect(() => {
    setVisibleAttendances(JSON.parse(timeslotVisibleParticipants));
    setHiddenAttendances(JSON.parse(timeslotHiddenParticipants));
  }, []);

  const lookupAttendances = async () => {
    setLoading(true);

    const splitReferences = references.trim().split(/\s/);
    if (splitReferences.length + visibleAttendances.length > MAX_PARTICIPANTS) {
      setCapacityReached(true)
      setLoading(false);
      return;
    }

    setCapacityReached(false)
    const res = await ScheduleAPI.findByTicketReferences(splitReferences, conferenceSlug);

    if (res) {
      setInvalidRef(res.data?.invalid_references || []);
      const newAttendances = res.data?.attendances || [];
      const filtered = checkForAttendancesAlreadyAdded(
        newAttendances,
        visibleAttendances,
        hiddenAttendances
      );
      setAlreadyAdded(filtered.alreadyAdded);
      setVisibleAttendances((prev) => [...prev, ...filtered.attendances]);
      setReferences('');
    }
    setLoading(false);
  };

  const detectReturn = async (event) => {
    if (event.key === 'Enter') {
      if (references.trim().length > 0) await lookupAttendances();
      else event.preventDefault();
    }
  };

  const makeParticipantHidden = (attendance) => {
    setHiddenAttendances([...hiddenAttendances, attendance]);
    setVisibleAttendances(visibleAttendances.filter((a) => a.id !== attendance.id));
  };

  const removeParticipant = (attendance, participantType) => {
    if (participantType === 'visible') {
      setVisibleAttendances(visibleAttendances.filter((a) => a.id !== attendance.id));
    } else if (participantType === 'hidden') {
      setHiddenAttendances(hiddenAttendances.filter((a) => a.id !== attendance.id));
    }
  };

  const makeParticipantVisible = (attendance) => {
    setVisibleAttendances([...visibleAttendances, attendance]);
    setHiddenAttendances(hiddenAttendances.filter((a) => a.id !== attendance.id));
  };

  return (
    <>
      <div className={'row'}>
        <div className={'col-md-12 col-xs-12'}>
          <label htmlFor={'timeslot_participant_input'}>Add Participants</label>
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-md-12 col-xs-12'}>
          <span className={'small help-block'}>
            Copy paste attendees' Ticket Reference from single spreadsheet column (use space as
            separator)
          </span>
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-xs-10 col-md-10'}>
          <textarea
            className={'form-control timeslot-participant-textarea'}
            id={'timeslot_participant_input'}
            rows="1"
            type={'textarea'}
            placeholder={'0000-XXX 0001-XXX 0002-XXX'}
            onChange={(e) => setReferences(e.currentTarget.value)}
            value={references}
            onKeyDown={detectReturn}
          />
        </div>
        <button
          className={'btn btn-primary text-uppercase'}
          type={'button'}
          onClick={lookupAttendances}
          disabled={loading}
        >
          {loading ? 'Adding...' : 'Add'}
        </button>
      </div>

      <div className={'row mt-1'}>
        <div className={'col-xs-12 col-md-12'}>
          {invalidRef.length > 0 && (
            <div className={'mt-1 text-danger'}>
              <span className={'glyphicon glyphicon-exclamation-sign'}></span>
              {` The following ${
                invalidRef.length
              } references do not have a result: ${invalidRef.join(', ')}`}
            </div>
          )}
          {alreadyAdded.length > 0 && (
            <div className={'mt-1 text-danger'}>
              <span className={'glyphicon glyphicon-exclamation-sign'}></span>
              {` The following ${
                alreadyAdded.length
              } references have been already added: ${alreadyAdded.join(', ')}`}
            </div>
          )}
           {capacityReached && (
            <div className={'mt-1 text-danger'}>
              <span className={'glyphicon glyphicon-exclamation-sign'}></span>
              {` The number of participants cannot be higher than ${MAX_PARTICIPANTS}.`}
            </div>
          )}
        </div>
      </div>
      <div className={'row mt-3'}>
        <div className={'col-xs-6 col-md-6'}>
          <label htmlFor={'timeslotVisibleParticipants'}>Visible Participants</label>
          <div id="timeslotVisibleParticipants" className="visible-participants ui-sortable">
            {visibleAttendances.length === 0 && <i>No participants selected</i>}
            {visibleAttendances.length > 0 &&
              visibleAttendances.map((attendance) => {
                return (
                  <Participant
                    key={attendance.id}
                    attendance={attendance}
                    onAction={makeParticipantHidden}
                    removeParticipant={removeParticipant}
                    fromList="visible"
                    isDraggable
                  />
                );
              })}
          </div>
        </div>
        <div className={'col-xs-6 col-md-6'}>
          <label htmlFor={'timeslotHiddenParticipants'}>Hidden Participants</label>
          <div id="timeslotHiddenParticipants" className="hidden-participants ui-sortable">
            {hiddenAttendances.length === 0 && <i>No hidden participants selected</i>}
            {hiddenAttendances.length > 0 &&
              hiddenAttendances.map((attendance) => {
                return (
                  <Participant
                    key={attendance.id}
                    attendance={attendance}
                    onAction={makeParticipantVisible}
                    removeParticipant={removeParticipant}
                    fromList="hidden"
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectParticipants;
