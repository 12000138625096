import $ from 'jquery'
import 'jquery-ui/ui/widgets/sortable'

function sendUpdatedIndex($target, $item) {
  const startIndex = $item.data('position')
  const startScore = $item.data('score')
  const pageSize = $target.data('per-page')
  const zeroIndexedPage = $target.data('page') - 1

  const newIndex = $item.index() + 1 + (pageSize * zeroIndexedPage)
  if (newIndex !== startIndex) {
    $.ajax({
      url: $item.data('update-url'),
      type: 'PUT',
      data: { attendance: { position: newIndex, score: startScore } },
      success() {
        window.location.reload()
      }
    });
  }
}

$(() => {
  $('div.dynamic-list-grid').sortable({
    handle: '.glyphicon.draggable-handle',
    stop(event, ui) {
      sendUpdatedIndex($(event.target), ui.item)
    }
  })
})
