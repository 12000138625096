import $ from 'jquery'

$(() => {
  $('.editable-area').on('click', '.open-edit[data-id]', (e) => {
    e.stopPropagation()
    const currentTarget = $(e.currentTarget)
    const wasEditing = currentTarget.attr('data-editing') === 'true'
    currentTarget.attr('data-editing', wasEditing ? 'false' : 'true')
    const editor = currentTarget.find('.editable-area-editor')
    const input  = editor.find('input[type="text"]')
    const name = currentTarget.find('.editable-area-name')
    const cell = currentTarget.find('.editable-area-item-cell')
    if (wasEditing) {
      editor.addClass('hide')
      name.removeClass('hide')
      cell.attr('colspan', 1)
    } else {
      editor.removeClass('hide')
      input.focus().select()
      name.addClass('hide')
      cell.attr('colspan', 2)
    }
  })
  $('.editable-area').on('click', '.open-edit[data-id] input', (e) => {
    $(e.currentTarget).focus()
    e.stopPropagation()
  })
  $('.editable-area').on('click', '.open-edit[data-id] button[tyoe="submit"]', (e) => {
    e.stopPropagation()
  })
})
