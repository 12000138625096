export default function($scope) {
  'ngInject'

  $scope.showColumns = {
    Day: true,
    'Start / End': true,
    Duration: true,
    Track: true,
    Location: true,
    'Content Producer': false,
    Title: true,
    Format: true,
    Description: false,
    Topics: false,
    'Hidden Participants': false,
    Speakers: true,
    Privacy: true,
    Actions: true
  }

  $scope.showResolved = {
    showResolved: true
  }
}
