/* eslint-disable no-undef */
export default function googlePlaces($interval) {
  'ngInject'

  return {
    restrict: 'A',
    link: (scope, element, attrs, model) => {
      const vm = scope

      vm.$watch(attrs.ngModel, (newVal, oldVal) => {
        if (newVal || newVal === oldVal) return
        scope.$emit('google_places:changed', {})
      })

      // wait for google to initialize
      const interval = $interval(() => {
        if (typeof google === 'undefined') return

        $interval.cancel(interval)

        const autocomplete = new google.maps.places.Autocomplete(element[0], {
          types: ['(cities)']
        })

        google.maps.event.addListener(autocomplete, 'place_changed', () => {
          scope.$emit('google_places:changed', autocomplete.getPlace())
        })
      }, 20, 250)
    }
  }
}
/* eslint-enable no-undef */
