function updatePreview(inputField) {
  const previewElemId = inputField.getAttribute('data-color-preview')
  const previewElem = document.getElementById(previewElemId)
  previewElem.style.backgroundColor = inputField.value
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-color-preview]').forEach((inputField) => {
    inputField.addEventListener('change', () => updatePreview(inputField))
    updatePreview(inputField)
  })
})
