const sortableHeading = function() {
  'ngInject'

  return {
    scope: {
      key: '@',
      name: '@',
      order: '='
    },
    templateUrl: 'sortable-heading.html'
  };
}

export default sortableHeading
