import React from 'react';
import PropTypes from 'prop-types';

import { Breadcrumbs, NavContext, OmniaUiThemeProvider } from '@websummit/omnia-ui-lib';

class BreadcrumbsWithContext extends React.Component {
  static propTypes = {
    toolGroups: PropTypes.array,
    events: PropTypes.array,
    currentConference: PropTypes.array
  };

  render() {
    const { toolGroups, globalToolGroups, currentConference, conferences, microPage } = this.props;

    return (
      <NavContext.Provider
        value={{
          contextualRoutes: toolGroups,
          currentEvent: currentConference && currentConference[0],
          events: conferences,
          globalRoutes: globalToolGroups,
          openState: '',
          setOpenState: () => {},
          openedMenuItem: '',
          setOpenedMenuItem: () => {}
        }}
      >
        <OmniaUiThemeProvider palette="websummit">
          {toolGroups && <Breadcrumbs microPage={microPage} />}
        </OmniaUiThemeProvider>
      </NavContext.Provider>
    );
  }
}

export default BreadcrumbsWithContext;
