import React from 'react'
import PropTypes from 'prop-types'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import ConferenceProps from './ConferenceProps'

import '../../_init/system_font.css'

class ConferenceTheme extends React.Component {
  render() {
    const { children, conference } = this.props
    const themeOptions = Object.assign({}, conference.materialUiTheme)
    themeOptions.typography.useNextVariants = true // needed until we upgrade to MUI v4
    const theme = createMuiTheme(themeOptions)
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  }
}

ConferenceTheme.propTypes = {
  children: PropTypes.node.isRequired,
  conference: ConferenceProps.isRequired
}

export default ConferenceTheme
