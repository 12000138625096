import $ from 'jquery'
import 'jquery-ui/ui/widgets/sortable'

function sendUpdatedPosition($target, $item) {
  const startPosition = $item.data('position');
  const oldPosition = $item.data('old-position');
  const selectedAttendance = $item.children().find('select').find(':selected').val()
  const pageSize = $target.data('per-page')
  const zeroIndexedPage = $target.data('page') - 1

  const newPosition = $item.index() + 1 + (pageSize * zeroIndexedPage)
  if (newPosition !== startPosition) {
    $.ajax({
      url: $item.data('update-url'),
      type: 'PUT',
      data: {
        global_list_item: {
          position: newPosition,
          old_position: oldPosition,
          attendance_id: selectedAttendance
        }
      },
      success() {
        window.location.reload();
      }
    });
  }
}

$(() => {
  $('div.global-list-grid').sortable({
    handle: '.glyphicon.draggable-handle',
    stop(event, ui) {
      sendUpdatedPosition($(event.target), ui.item)
    }
  })
})
