import React from 'react';
import PropTypes from 'prop-types';

import {
  EventSwitch,
  NavContext,
  OmniaUiThemeProvider,
  SideBar,
  SideNavGenerator
} from '@websummit/omnia-ui-lib';
import styled from 'styled-components';

const SideNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;

  > div {
    height: 100%;
  }
`;

const prefix = 'omnia-ui-v22-';

class NavContextGenerator extends React.Component {
  static propTypes = {
    currentConference: PropTypes.array,
    toolGroups: PropTypes.array,
    globalToolGroups: PropTypes.array,
    events: PropTypes.array,
    sideBarOpenState: PropTypes.number
  };

  lsGet = (key) => {
    return JSON.parse(localStorage.getItem(prefix + key));
  };

  state = {
    openState:
      window.innerWidth <= 1200
        ? false
        : this.lsGet('openState') !== undefined
        ? this.lsGet('openState')
        : window.innerWidth > 1200,
    openedMenuItem: ''
  };

  lsUpdate = (key, val) => {
    localStorage.setItem(prefix + key, JSON.stringify(val));
  };

  lsRemove = (key) => {
    localStorage.removeItem(prefix + key);
  };

  setOpenState = (value) => {
    this.lsUpdate('openState', value);
    this.setState({
      openState: value
    });
  };

  setOpenedMenuItem = (value) => {
    this.setState({
      openedMenuItem: value
    });
  };

  render() {
    const { toolGroups, globalToolGroups, currentConference, conferences } = this.props;

    return (
      <NavContext.Provider
        value={{
          contextualRoutes: toolGroups,
          currentEvent: currentConference && currentConference[0],
          events: conferences,
          globalRoutes: globalToolGroups,
          openState: this.state.openState,
          setOpenState: this.setOpenState,
          openedMenuItem: this.state.openedMenuItem,
          setOpenedMenuItem: this.setOpenedMenuItem
        }}
      >
        <OmniaUiThemeProvider palette="websummit">
          <SideNavWrapper>
            <div>
              <SideBar
                children={
                  <>
                    <EventSwitch />
                    <SideNavGenerator bottomMenu={'settings'} />
                  </>
                }
              />
            </div>
          </SideNavWrapper>
        </OmniaUiThemeProvider>
      </NavContext.Provider>
    );
  }
}

export default NavContextGenerator;
