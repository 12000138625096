import _ from 'underscore'

export default function($scope) {
  'ngInject'

  $scope.selectedConference = null

  $scope.updateSelectedConference = function() {
    if (_.isEmpty($scope.selectedConferenceJson)) {
      $scope.selectedConference = null
    } else {
      $scope.selectedConference = JSON.parse($scope.selectedConferenceJson)
    }
  }

  $scope.isConferenceSelected = function() {
    return $scope.selectedConference !== null
  }
}
