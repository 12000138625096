import React from 'react';
import PropTypes from 'prop-types';

import { LinkListGroup, NavContext, OmniaUiThemeProvider } from '@websummit/omnia-ui-lib';

class TopNavGenerator extends React.Component {
  static propTypes = {
    currentConference: PropTypes.array,
    toolGroups: PropTypes.array,
    globalToolGroups: PropTypes.array,
    events: PropTypes.array,
    sideBarOpenState: PropTypes.number
  };

  render() {
    const { toolGroups, globalToolGroups, currentConference, conferences } = this.props;

    return (
      <NavContext.Provider
        value={{
          contextualRoutes: toolGroups,
          currentEvent: currentConference && currentConference[0],
          events: conferences,
          globalRoutes: globalToolGroups,
          openState: false,
          setOpenState: () => {},
          openedMenuItem: '',
          setOpenedMenuItem: () => {}
        }}
      >
        <OmniaUiThemeProvider palette="websummit">
          <LinkListGroup />
        </OmniaUiThemeProvider>
      </NavContext.Provider>
    );
  }
}

export default TopNavGenerator;
