import copyValueToClipboard from '../_components/clipboard';
import { saveEventToMatomo } from './matomo_gateway';
import { capitalize } from './common';

export default function DeeplinksCtrl($scope, $http) {
  'ngInject';

  $scope.myDeeplink = '';
  $scope.showDeeplinkAlert = false;
  $scope.deeplinkError = false;
  $scope.deeplinkTypesDesktopFallback = ['schedule_tracks'];

  $scope.generateDesktopFallbackUrl = function (entityProperties, fallbackProperties) {
    if (!$scope.deeplinkTypesDesktopFallback.includes(entityProperties.type)) return null;

    let fallbackUrl = `https://${fallbackProperties.domain}`;
    let filters = null;

    switch (entityProperties.type) {
      case 'schedule_tracks':
        const scheduleTrackNameCapitalized = fallbackProperties.name
          .split(' ')
          .map((word) => capitalize(word))
          .join(' ');

        let scheduleRefinedQuery = `"refinementList":{"schedule_track.name":"${scheduleTrackNameCapitalized}"`;
        if (entityProperties.curated)
          scheduleRefinedQuery = `"menu":{"curated_tracks.name":"${scheduleTrackNameCapitalized}"`;

        filters = btoa(`{${scheduleRefinedQuery}},"page":1,"configure":{"hitsPerPage":20}}`);
        fallbackUrl += `/schedule?q=${filters}`;
        break;
      default:
        break;
    }

    return fallbackUrl;
  };

  $scope.generateProperties = function (entityId) {
    if (!entityId) return [];

    return [
      {
        name: 'id',
        value: entityId
      }
    ];
  };

  $scope.removeDeepLinkAlert = function () {
    $scope.showDeeplinkAlert = false;
  };

  $scope.generateDeeplink = function (
    conferenceSlug,
    entityId,
    entityProperties,
    currentAdminEmail,
    token,
    fallbackProperties = null
  ) {
    if ($scope.myDeeplink !== '') {
      $scope.showDeeplinkAlert = true;
      copyValueToClipboard({ value: $scope.myDeeplink });
      return;
    }

    if (!$scope.deeplinkUrl) throw new Error('missing deeplink url');

    const desktopFallbackUrl = $scope.generateDesktopFallbackUrl(
      entityProperties,
      fallbackProperties
    );
    const properties = $scope.generateProperties(entityId);

    return $http
      .post(
        $scope.deeplinkUrl,
        {
          type: entityProperties.type,
          data: {
            event: conferenceSlug,
            desktopFallbackUrl,
            properties
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(
        function successCallback(response) {
          $scope.showDeeplinkAlert = true;
          $scope.deeplinkError = false;
          $scope.myDeeplink = response.data.link;
          copyValueToClipboard({ value: response.data.link });
          if ($scope.railsProdEnv) {
            saveEventToMatomo('Deeplink_Usage', [conferenceSlug, entityProperties.type, entityId]);
            saveEventToMatomo('Deeplink_Authorship', [conferenceSlug, currentAdminEmail]);
          }
        },
        function errorCallback(response) {
          console.log(response);
          $scope.showDeeplinkAlert = true;
          $scope.deeplinkError = true;
        }
      );
  };
}
