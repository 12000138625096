import React from 'react';
import PropTypes from 'prop-types';

import { TopNav } from '@websummit/omnia-ui-lib';
import { OmniaUiThemeProvider } from '@websummit/omnia-ui-lib';
import { NavContext, NavContextProps } from '@websummit/omnia-ui-lib';


class TopNavGenerator extends React.Component {
  state = {
    openState:
      'Click the button to receive greetings'
  };
  
  setOpenState = (value) => {
    // Changing state
    this.setState({
      openState: value
    });
  };
  
  render() {
    const { globalRoutes } = this.props;
    const setOpenState = this.setOpenState;
    const { openState } = this.state;
    
    return (
      <NavContext.Provider value={{
        contextualRoutes: {},
        currentEvent: undefined,
        events: {},
        globalRoutes,
        openState,
        setOpenState
      }}>
        <OmniaUiThemeProvider palette="websummit">
            <TopNav />
        </OmniaUiThemeProvider>
      </NavContext.Provider>
    );
  }
  
  static propTypes = {
    globalRoutes: PropTypes.array
  };
}

export default TopNavGenerator;
