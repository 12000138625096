const checkForAttendancesAlreadyAdded = (attendances, visibleAttendances, hiddenAttendances) => {
  const tmpAdded = [];
  const filteredAttendances = attendances.filter((elm, idx) => {
    const found =
      visibleAttendances.find((a) => a.ticket.booking_ref === elm.ticket.booking_ref) ||
      hiddenAttendances.find((a) => a.ticket.booking_ref === elm.ticket.booking_ref);

    if (found) {
      tmpAdded.push(elm.ticket.booking_ref);
    }

    return !found;
  });
  return { attendances: filteredAttendances, alreadyAdded: tmpAdded };
};

export { checkForAttendancesAlreadyAdded };
