import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
});

let openSnackBar

class SnackBarNotifier extends React.Component {
  state = { open: false, message: '' }

  componentDidMount() {
    openSnackBar = this.openSnackBar
  }

  handleSnackBarOpen = () => {
    this.setState({ open: true })
  }

  handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') return

    this.setState({ open: false })
  }

  openSnackBar = ({ message }) => {
    this.setState({ open: true, message })
  }

  render() {
    const { classes } = this.props
    const { open, message } = this.state

    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{message}</span>}
          action={
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackBarClose}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      </React.Fragment>
    );
  }
}

SnackBarNotifier.propTypes = {
  classes: PropTypes.object.isRequired
}

export function openSnackbar({ message }) {
  openSnackBar({ message })
}

export default withStyles(styles)(SnackBarNotifier)
