const formImageWrapperSelector = '.form-image-upload'
const formImageFieldSelector   = '.form-image-upload-control'
const thumbnailPreviewSelector = '.form-image-upload-thumbnail'

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll(formImageFieldSelector).forEach((imageField) => {
    imageField.addEventListener('change', (event) => {
      const reader = new FileReader()
      reader.onload = (element) => {
        const wrapper = imageField.closest(formImageWrapperSelector)
        const previewImg = wrapper.querySelector(thumbnailPreviewSelector)
        previewImg.src = element.target.result
      }
      reader.readAsDataURL(imageField.files[0])
    })
  })
})
