import angular from 'angular'
import Rails from 'rails-ujs'
import $ from 'jquery/src/jquery'
import 'bootstrap-sass/assets/javascripts/bootstrap'
import 'angular-ui-bootstrap'
import 'flatpickr'
import 'flatpickr/dist/flatpickr.css'

import '../_init'
import '../_init/react'

// Directives
import sortableHeadingDirective from './tickets/directives/sortable_heading.directive'
import googlePlacesDirective from '../_directives/google_places/google_places.directive'

// Controllers
import ConferenceCtrl from './conferences/controllers/ConferenceCtrl'
import AppearanceCtrl from './appearance/controllers/AppearanceCtrl'
import AttendanceCtrl from './attendance/controllers/AttendanceCtrl'
import AttendancesCtrl from './schedules/controllers/AttendancesCtrl'
import TimeslotsCtrl from './schedules/controllers/TimeslotsCtrl'
import ReleasesCtrl from './tickets/controllers/ReleasesCtrl'
import ListCtrl from './lists_manager/controllers/ListCtrl'
import EditTimeslotCtrl from './schedules/controllers/EditTimeslotCtrl'
import EditTimeslotTrackCtrl from './schedules/controllers/EditTimeslotTrackCtrl'
import CloneTimeslotTracksCtrl from './schedules/controllers/CloneTimeslotTracksCtrl'
import DeeplinksCtrl from './DeeplinksCtrl';

// Styles
import '../_init/icons'
import './omnia.scss'
import './schedules/schedules.scss'
import './tickets/tickets.scss'
import './ticket_support/ticket_support.scss'

//
import '../_components/jquery_csrf_token'
import '../_components/highlight_required'
import '../_components/image_upload_preview'
import '../_components/color_preview'
import '../_components/sortable_table'
import '../_components/editable_area'
import "./schedules/timeslot_participants"
import './lists_manager/global_lists/sortable_list'
import './lists_manager/lists/dynamic_list'
import './lists_manager/lists/manual_list'
import './matomo_gateway'
import copyValueToClipboard from '../_components/clipboard'

const app = angular.module('OmniaApp', ['ui.bootstrap'])
app.controller('ConferenceCtrl', ConferenceCtrl)
app.controller('AppearanceCtrl', AppearanceCtrl)
app.controller('AttendanceCtrl', AttendanceCtrl)
app.controller('AttendancesCtrl', AttendancesCtrl)
app.controller('TimeslotsCtrl', TimeslotsCtrl)
app.controller('ReleasesCtrl', ReleasesCtrl)
app.controller('ListCtrl', ListCtrl)
app.controller('EditTimeslotCtrl', EditTimeslotCtrl)
app.controller('EditTimeslotTrackCtrl', EditTimeslotTrackCtrl)
app.controller('CloneTimeslotTracksCtrl', CloneTimeslotTracksCtrl)
app.controller('DeeplinksCtrl', DeeplinksCtrl);
app.directive('sortableHeading', sortableHeadingDirective)
app.directive('googlePlaces', googlePlacesDirective)

window.copyValueToClipboard = copyValueToClipboard
$(document).ready(() => $('[data-toggle="tooltip"]').tooltip());
Rails.start();
