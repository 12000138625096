import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

const styles = {
  alert: {
    borderRadius: '5px',
    color: '#fff',
    fontSize: '0.9rem',
    margin: '0.75rem 0',
    padding: '0.75rem',

    '&.success': {
      background: '#7ed321',
      border: 'solid 1px #9de74c'
    },

    '&.info': {
      background: '#063859',
      border: 'solid 1px #0e6caa'
    },

    '&.warning': {
      background: '#f39c12',
      border: 'solid 1px #f0b250'
    },

    '&.danger': {
      background: '#d1122a',
      border: 'solid 1px #d24e5f'
    },
  }
}

class Alert extends React.Component {
  render() {
    const { classes, message, type } = this.props

    return (
      <div className={`${classes.alert} ${this.props.type}`}>
        {message}
      </div>
    )
  }
}

Alert.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
  type: PropTypes.string
}

export default withStyles(styles)(Alert)
