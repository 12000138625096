import axios from 'axios';

const findByTicketReferences = async (references, conferenceSlug) => {
  if (references.length === 0) return;

  try {
    return await axios.get(
      `/conferences/${conferenceSlug}/schedules/attendances/find_by_ticket_references`,
      {
        params: { ticket_references: references }
      }
    );
  } catch (error) {
    console.error('Failed to lookup attendances:', error);
    return;
  }
};

export default { findByTicketReferences };
