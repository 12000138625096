import moment from 'moment'
import flatpickr from 'flatpickr'

export default function EditTimeslotCtrl($scope) {
  'ngInject'

  const momentFormat = 'YYYY-MM-DD HH:mm:ss'
  const backendFormat = 'YYYY-MM-DD HH:mm'
  const picker = flatpickr('.picker-start-time', {
    enableTime: true,
    dateFormat: momentFormat,
    allowInput: false,
    onClose(selectedDates, dateStr, instance) {
      $scope.startTime = dateStr
      $scope.updateEndTime()
      $scope.$apply()
    },
    parseDate(dateString, format) {
      return moment(dateString, backendFormat).toDate()
    },
    formatDate(date, format) {
      return moment(date).format(format)
    }
  })

  function parseTime(time) {
    return moment.utc(time, momentFormat)
  }

  function recalculateDuration() {
    const start = parseTime($scope.startTime)
    const end = parseTime($scope.endTime)
    if (start.isValid() && end.isValid()) {
      $scope.duration = moment.duration(end.diff(start)).asMinutes()
    }
  }

  $scope.setTime = function(start, end) {
    $scope.startTime = moment.utc(start).format(momentFormat)
    $scope.endTime = moment.utc(end).format(momentFormat)
    recalculateDuration()
  }

  $scope.updateEndTime = function() {
    const duration = moment.duration($scope.duration, 'minutes')
    const start = parseTime($scope.startTime)
    if (start.isValid()) {
      $scope.endTime = start.add(duration).format(momentFormat)
    }
  }
}
