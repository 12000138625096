import React from 'react';

const Participant = ({
  attendance,
  onAction,
  removeParticipant,
  isDraggable = false,
  fromList
}) => {
  const fromListAssets = {
    visible: {
      icon: '→',
      title: 'Hide participant',
      hiddenInputTimeslotName: 'attendance_ids'
    },
    hidden: {
      icon: '←',
      title: 'Unhide participant',
      hiddenInputTimeslotName: 'hidden_attendance_ids'
    }
  };

  return (
    <div className="btn-speaker btn-group btn-group-sm ng-scope" key={attendance.id}>
      <button className="btn btn-info" type="button">
        {attendance.person.first_name + ' ' + attendance.person.last_name}
        <strong className="small">{' ' + attendance.ticket.booking_ref}</strong>
      </button>
      <button
        className="btn btn-warning"
        onClick={() => onAction(attendance)}
        title={fromListAssets[fromList].title}
        type="button"
      >
        {fromListAssets[fromList].icon}
      </button>
      <button
        className="btn btn-danger"
        title="Remove participant"
        onClick={(e) => removeParticipant(attendance, fromList)}
        type="button"
      >
        ×
      </button>
      {isDraggable && <div className="btn btn-primary draggable-handle ui-sortable-handle">↕</div>}
      <input
        name={`timeslot[${fromListAssets[fromList].hiddenInputTimeslotName}][]`}
        type="hidden"
        autoComplete="off"
        value={attendance.id}
      />
    </div>
  );
};

export default Participant;
