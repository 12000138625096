import $ from "jquery";

export default function($scope) {
  "ngInject";

  let counters = {
    basic: 0,
    branding: 0,
    configurations: 0,
    investor_meetings: 0,
    others: 0
  };
  let disableSubmission = false;
  const defaultSubmitButtonValue = $("form :submit").val();

  function callback(event) {
    ["basic", "branding", "configurations", "startups", "others"].forEach(
      (element, index) => {
        $(`div#${element} .form-control`).each((i, input) => {
          if (!input.validity.valid) {
            counters[element] += 1;
          }
        });

        const tab = $(`ul.nav.nav-tabs li:nth-child(${index + 1}) > a`);
        let tabText;

        if (element === 'basic') {
          tabText = 'Basic info';
        } else {
          tabText = element.charAt(0).toUpperCase() + element.slice(1);
        }

        if (counters[element] > 0) {
          tab.html(`${tabText} <span class="badge">${counters[element]}</span>`);
          disableSubmission = true;
        } else {
          tab.html(tabText);
        }
      }
    );

    const submitButton = $("form :submit");
    submitButton.attr("disabled", disableSubmission);
    if (disableSubmission) {
      submitButton.val("Please fill all required fields");
      submitButton.addClass("btn-danger");
      submitButton.removeClass("btn-primary");
    } else {
      submitButton.val(defaultSubmitButtonValue);
      submitButton.addClass("btn-primary");
      submitButton.removeClass("btn-danger");
    }
    counters = {
      basic: 0,
      branding: 0,
      configurations: 0,
      others: 0
    };
    disableSubmission = false;
  }

  $("form .form-control").blur(callback);
  $('ul.nav.nav-tabs > li[class!="navbar-right"]').click(callback);
}
