export default function ($scope, $http) {
  'ngInject';

  $scope.searchQuery = '';
  $scope.loading = false;

  $scope.searchAttendances = function (query) {
    if (!query) return [];
    if (!$scope.searchUrl) throw new Error('missing search url');
    $scope.loading = true;

    const url = `${$scope.searchUrl}?query=${query}`;
    return $http.get(url).then(function successCallback(response) {
      $scope.loading = false;
      return response.data.data;
    });
  };

  $scope.createListItem = function (selected, conferenceSlug, listName, userEmail) {
    $http
      .post($scope.createListItemURL, { attendance_id: selected.id })
      .then(() => {
        _paq.push([
          'trackEvent',
          'Manual List actions',
          'Search single attendee',
          conferenceSlug,
          `${listName}-${userEmail}`
        ]);
        window.location.reload();
      })
      .catch((err) => window.console.log(err.status, err.statusText));
  };

  $scope.selectAttendance = function (selected) {
    $scope.attendance = selected;
  };
}
