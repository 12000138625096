import $ from 'jquery'
import 'jquery-ui/ui/widgets/sortable'

$(() => {
  $('.sortable-table').sortable({
    axis: 'y',
    update(event, ui) {
      const url = $(this).data('update-url')
      const options = {
        expression: /(.+)_(.+)/
      }
      $.post(url, $(this).sortable('serialize', options))
    }
  })
})
