import React from 'react'
import PropTypes from 'prop-types'

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Fab, withStyles } from '@material-ui/core'

const styles = {
  scrollToTop: {
    bottom: '20px',
    position: 'fixed',
    right: '20px',

    '& .message': {
      marginLeft: '10px'
    }
  }
}

class ScrollToTop extends React.Component {
  state = {
    displayable: true
  }

  componentDidMount() {
    window.onscroll = () => { this.isInViewport() }
  }

  isInViewport = () => {
    document.body.scrollTop > this.props.topOffset || document.documentElement.scrollTop > this.props.topOffset
      ? this.setState({ displayable: false })
      : this.setState({ displayable: true })
  }

  render() {
    const { classes, message } = this.props

    const handleClick = () => {
      const element = document.querySelector(`.${this.props.targetClassName}`)
      element.scrollIntoView()
    }

    return (!this.state.displayable &&
      <Fab
        aria-label={message}
        className={`${classes.scrollToTop}`}
        color="primary"
        onClick={handleClick}
        variant="extended">
          <ArrowUpwardIcon fontSize="small"/> <span className="message">{message}</span>
      </Fab>
    )
  }
}

ScrollToTop.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  targetClassName: PropTypes.string.isRequired,
  topOffset: PropTypes.number.isRequired
}

export default withStyles(styles)(ScrollToTop)
