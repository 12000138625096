import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel, Typography, withStyles } from '@material-ui/core'

const styles = () => ({
  consentText: {
    marginRight: '16px'
  }
})

function GdprCheckbox(props) {
  const [selected, setSelected] = useState(false);
  const { classes, gdprCheckboxText, onSelectGdprCheckbox } = props;

  return (
    <FormControlLabel
      className={classes.consentText}
      control={
        <Checkbox
          checked={selected}
          onChange={
            () => {
              onSelectGdprCheckbox();
              setSelected(!selected)
            }
          }
        />
      }
      label={
        <Typography variant='body1'>
          {gdprCheckboxText}
        </Typography>
      }
      labelPlacement='start'
    />
  )
}

GdprCheckbox.propTypes = {
  gdprCheckboxText: PropTypes.string.isRequired,
  onSelectGdprCheckbox: PropTypes.func.isRequired
}

export default withStyles(styles)(GdprCheckbox);
