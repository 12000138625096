export default function($scope, $http) {
  'ngInject'

  $scope.$on('google_places:changed', (_event, place) => {
    const locationField = document.getElementById('location_data')
    locationField.value = JSON.stringify(place)
  })

  $scope.searchQuery = ''
  $scope.attendanceAppearances = []
  $scope.loading = false
  $scope.loadingEndorsee = false

  $scope.searchAttendances = function(query, conferenceId) {
    if (!query) return []
    if (!$scope.attendanceSearchUrl) throw new Error('missing search url')

    $scope.loading = true;
    const url = `${$scope.attendanceSearchUrl}?query=${query}`
    return $http.get(url).then(function successCallback(response) {
      $scope.loading = false;
      return response.data.data;
    })
  }

  $scope.onSelect = function(item) {
    $scope.attendanceAppearances.push({
      attendance_id: item.id,
      name: item.person.name,
      featured: false
    })
    $scope.searchQuery = ''
  }

  $scope.removeParticipant = function(attendanceAppearance) {
    $scope.attendanceAppearances.splice(
      $scope.attendanceAppearances.indexOf(attendanceAppearance), 1
    )
  }

  $scope.changeFeatured = function(attendanceAppearance, value) {
    attendanceAppearance.featured = value
  }

  $scope.searchAppearances = function(query) {
    if (!query) return []
    if (!$scope.appearanceSearchUrl) throw new Error('missing search url')
    $scope.loadingEndorsee = true;

    const url = `${$scope.appearanceSearchUrl}?query=${query}`
    return $http.get(url).then(function successCallback(response) {
      $scope.loadingEndorsee = false;
      return response.data.data;
    })
  }

  $scope.onAppearanceSelect = function(item) {
    $scope.endorsedAppearances.push({
      id: item.id,
      name: item.name
    })
    $scope.searchQuery = ''
  }

  $scope.removeEndorsed = function(endorsedAppearance) {
    $scope.endorsedAppearances.splice(
      $scope.endorsedAppearances.indexOf(endorsedAppearance), 1
    )
  }
}
